<template>
  <component
    :is="tag || 'div'"
    :class="[
      'media-text-cta parent-bg',
      {
        'is-special': isSpecial,
        'has-no-media': !blok.media?.length,
        'is-magazine': blok.is_magazine,
      },
    ]"
  >
    <template v-if="isVirtualVisit">
      <div class="wrapper-media">
        <span class="badge"><SvgIcon name="func_360" class="fill" /></span>
        <StoryblokComponent
          v-if="blok.media?.length === 1"
          :blok="blok.media[0]"
          class="media"
          :size-hint="sizeHint"
        />
      </div>
    </template>
    <template v-else>
      <StoryblokComponent
        v-if="blok.media?.length === 1"
        :blok="blok.media[0]"
        class="media"
        :size-hint="sizeHint"
      />
    </template>
    <div v-if="blok.media?.length > 1" class="media-carousel">
      <GenericCarousel
        v-if="blok.media?.length > 1"
        :items="blok.media"
        :size-hint="sizeHint"
      />
    </div>
    <div
      v-if="title || subtitle || text || blok.cta_label"
      :class="`text-cta-wrapper ${alignCss}`"
    >
      <p v-if="blok.is_file || isFile" class="resource-info size-xs">
        <SvgIcon name="func_brochure" class="gradient-fill-svg" />
        {{
          blok.is_magazine
            ? blok.magazine_name
            : useMicroCopy("resource.guides")
        }}
      </p>
      <div
        v-if="title || subtitle || text"
        :class="['text-wrapper', textSize]"
        v-html="`${title}${subtitle}${text}`"
      ></div>
      <p
        v-if="textIsTooLong && isEditorial"
        class="expand-modal-view"
        :class="['text-wrapper', textSize]"
      >
        <SimpleButton
          :round="false"
          :flat="true"
          :solid="false"
          :title="useMicroCopy(`editorial.cta.label`)"
          xl
          @click="openModalView"
        >
          {{ useMicroCopy(`editorial.cta.label`) }}
        </SimpleButton>
      </p>
      <div
        v-if="blok.reading_time || blok.tag_list?.length"
        class="resource-info-wrapper"
      >
        <p v-if="blok.reading_time" class="resource-info size-xs">
          <SvgIcon name="func_clock" class="gradient-stroke-svg" />
          {{ blok.reading_time }}
        </p>
        <ul v-if="blok.tag_list?.length" class="tag-list">
          <li v-for="(item, index) in blok.tag_list" :key="`tag${index}`">
            <span class="tag">{{ useMicroCopyTagLabel(item) }}</span>
          </li>
        </ul>
      </div>

      <ul
        v-if="blok?.informations?.length > 0"
        class="infos-list margin-xs-top"
      >
        <li
          v-for="information in blok.informations"
          :key="information._uid"
          class="infos-item"
        >
          <SvgIcon
            v-if="information.information_icon"
            :name="information.information_icon"
          />
          <template v-if="information.information_text">
            {{ information.information_text }}
          </template>
        </li>
      </ul>
      <SimpleButton
        v-if="
          (blok.cta_link && blok.cta_link.url) ||
          (blok.cta_link && blok.cta_link.story?.url) ||
          (blok.cta_link && blok.cta_link.cached_url)
        "
        :link="blok.cta_link"
        :class="[buttonMarginTop, { 'no-label': !blok.cta_label }]"
        :round="!blok.cta_label && !blok.cta_plain"
        :flat="blok.cta_plain"
        :solid="blok.cta_plain"
        :title="!blok.cta_label && blok.cta_title ? blok.cta_title : null"
        xl
        @click="emit('dynamic-slider-tracking', blok)"
      >
        <SvgIcon v-if="!blok.cta_label" name="func_right" class="fill" />
        {{ blok.cta_label }}
      </SimpleButton>
      <SimpleButton
        v-else-if="
          (blok.pdf_file && blok.pdf_file?.filename) || blok.pdf_file_url
        "
        :href="blok.pdf_file?.filename || blok.pdf_file_url"
        :class="[buttonMarginTop, { 'no-label': !blok.cta_label }]"
        :round="!blok.cta_label && !blok.cta_plain"
        :flat="blok.cta_plain"
        :solid="blok.cta_plain"
        :title="!blok.cta_label && blok.cta_title ? blok.cta_title : null"
        xl
        @click="emit('dynamic-slider-tracking', blok)"
      >
        <SvgIcon v-if="!blok.cta_label" name="func_right" class="fill" />
        {{ blok.cta_label }}
      </SimpleButton>
    </div>
    <ClientOnly>
      <Teleport to=".modals">
        <GenericModal
          v-if="textIsTooLong"
          ref="modalView"
          name="modalView"
          css-class="modal-view"
        >
          <div class="modal-view-inner">
            <div
              v-if="title"
              :class="['text-title', textSize]"
              v-html="`${title}`"
            ></div>
            <div
              v-if="subtitle"
              :class="['text-subtitle', textSize]"
              v-html="`${subtitle}`"
            ></div>
            <StoryblokComponent
              v-if="blok.media?.length === 1"
              :blok="blok.media[0]"
              class="media"
              :size-hint="sizeHint"
            />
            <div v-if="blok.media?.length > 1" class="media-carousel">
              <GenericCarousel
                v-if="blok.media?.length > 1"
                :items="blok.media"
                :size-hint="sizeHint"
              />
            </div>
            <div
              v-if="title || subtitle || text || blok.cta_label"
              :class="`text-cta-wrapper ${alignCss}`"
            >
              <div
                v-if="text"
                :class="['text-wrapper', textSize]"
                v-html="`${text}`"
              ></div>
            </div>
          </div>
        </GenericModal>
      </Teleport>
    </ClientOnly>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  level: {
    type: Number,
    required: false,
    default: 1,
  },
  titleSize: {
    type: String,
    required: false,
    default: "size-m",
  },
  textSize: {
    type: String,
    required: false,
    default: "size-xs",
  },
  buttonMarginTop: {
    type: String,
    required: false,
    default: "margin-xs-top",
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  isCentered: {
    type: Boolean,
    required: false,
    default: true,
  },
  sizeHint: {
    type: Number,
    required: false,
    default: 100,
  },
  isFile: {
    type: Boolean,
    required: false,
    default: false,
  },
  isVirtualVisit: {
    type: Boolean,
    required: false,
    default: false,
  },
  isEditorial: {
    type: Boolean,
    required: false,
    default: false,
  },
  isEditorial: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["dynamic-slider-tracking"]);
const alignCss = props.isCentered ? "center" : "";

const { title, subtitle, text } = useRichText(
  props.blok,
  props.level,
  props.titleSize
);

const isSpecial = computed(() => {
  return props.blok.is_special;
});

const textIsTooLong = ref(false);

if (props.isEditorial) {
  onMounted(() => {
    const div = document.createElement("div");
    div.innerHTML = text.value;
    const content = div.textContent || "";
    textIsTooLong.value = content.length > 300;
  });
}

const modalView = ref(null);
const openModalView = () => {
  modalView.value?.openModal();
};
</script>

<style lang="scss" scoped>
.media {
  display: flex;
  border-radius: $radius-xs;
  overflow: hidden;
  width: 100%;

  :deep(img) {
    width: 100%;
  }
}

.text-cta-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &.center {
    align-items: center;
    text-align: center;
  }
}

.text-wrapper {
  max-width: 37.5rem;
  margin: 0 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  :deep(.title),
  :deep(.subtitle) {
    margin-bottom: 0.5rem;
  }
  :deep(a) {
    color: inherit !important;
  }
}

:deep(.youtube-player) {
  aspect-ratio: 1.33;
}

.media-carousel {
  width: 100%;
  overflow: hidden;
}

.infos {
  &-list {
    margin-right: 5%;
    margin-left: 5%;
    width: 90%;
    border-radius: $radius-m;
    overflow: hidden;
    text-align: left;
    @include for-tablet-portrait-up {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &-item {
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
    width: 100%;
    @include pair-7;
    @include for-tablet-portrait-up {
      width: calc(50% - 1.25rem);
      &:first-child {
        border-top-right-radius: 1.25rem;
      }
      &:nth-child(2) {
        border-top-left-radius: 1.25rem;
      }
      &:nth-last-child(2),
      &:last-child {
        border-bottom-right-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
      }
    }
  }
}
.alternate .infos-item {
  @include pair-5;
}

.resource-info {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
  &:first-child {
    margin-bottom: 0.5rem;
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-end;
  @include for-tablet-portrait-up {
    position: absolute;
    bottom: 1rem;
    justify-content: flex-start;
  }
  .tag {
    display: inline-block;
    @include pair-7;
    padding: 0.125rem 0.5rem;
    border-radius: $radius-l;
    white-space: nowrap;
    font-size: 0.875rem;
  }
}

.is-guide .tag-list {
  justify-content: flex-start;
  .tag {
    @include pair-5;
  }
}

.modal-view {
  border: 1px solid red;
}

.modal-view .modal-content-wrapper .modal-content {
  .modal-view-inner {
    display: grid;
    gap: 1rem;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    @include for-desktop-up {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      margin-left: -5rem;
      margin-right: -5rem;
    }

    .text-title,
    .text-subtitle {
      grid-column: 1/-1;
      text-align: center;

      @include for-desktop-up {
        grid-column: 2/-1;
        text-align: left;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .text-subtitle {
      margin-bottom: 2rem;
    }

    .media {
      grid-column: 1/1;
      aspect-ratio: 4/3;
    }

    .text-wrapper {
      max-width: fit-content;
      padding-left: 0;
      padding-right: 0;

      @include for-desktop-up {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      :deep(p, ol, ul) {
        margin-bottom: 0.5rem;
      }

      ol,
      ul {
        margin-left: 1rem;
      }

      ul {
        list-style: disc;
      }
    }
  }
}

.wrapper-media {
  .badge {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 5.6875rem;
    height: 5.6875rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include brand;

    @include for-tablet-portrait-up {
      width: 13.125rem;
      height: 13.125rem;
    }
    @include for-tablet-landscape-up {
      width: 12rem;
      height: 12rem;
    }
    .icon {
      color: var(--solid-07);
    }
  }

  .icon {
    width: 3.6875rem;

    @include for-tablet-portrait-up {
      width: 8.5625rem;
    }
    @include for-tablet-landscape-up {
      width: 7.8125rem;
    }
  }
}

.is-magazine {
  @include pair-2;
  :deep(.media) {
    padding: 2rem 0;
    background: url("/assets/svg/bg-magazine.svg") center center no-repeat
      var(--brand);
    background-size: cover;
    height: 12.625rem;
    @include for-tablet-landscape-up {
      height: 100%;
    }
    .asset-image {
      width: auto;
      max-width: 40%;
      margin-left: auto;
      margin-right: auto;
      @include for-tablet-landscape-up {
        max-width: 45%;
      }
    }
  }
}
</style>

<style lang="scss">
.animated-services .media-text-cta.solid-03 {
  @include pair-3;
  background: none;
}
</style>
